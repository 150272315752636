
import state from "./moduleUintModelState.js"
import mutations from "./moduleUintModelMutations.js"
import getters from "./moduleUintModelGetters.js"
import actions from './moduleUintModelActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
