<template>
  <div id="page-user-edit">

    <vx-card class="shadow-md rounded-lg">
      <h4 style="font-family:almarai ;">إختر الوحدة</h4>
      <br>
      <div class="vx-row w-full">
          <div class="vx-col lg:w-1/2 checkbox-wrapper">
            <input type="checkbox" v-model="OwnerPayment.IsAllUnits" id="selectAllUnits">
             <label for="selectAllUnits" class="checkbox-label">كل الوحدات</label>
         <div v-if="OwnerPayment.IsAllUnits" class="warning-message">سوف يتم إرسال المصروف إلي كل الوحدات!</div>
     </div>
    </div>


      <div class="vx-row w-full">
        <div class="vx-col w-full lg:w-2/3">
          <div class="vx-row w-full">
            <div style="width: 200px;  padding:10px ;">
              <label style="font-size: 16px;font-family:almarai ;">{{ $t("UnitModel") }}</label>
              <v-select
                  label="name"
                  class="mt-2 w-full"
                  name="name"
                  v-model="OwnerPayment.UnitModelId"
                  :reduce="(x) => x.id"
                  :options="unitModelOptions"
                  :disabled="OwnerPayment.IsAllUnits"
                  @input="getBuildingNumbersByModelId(OwnerPayment.UnitModelId)"
              />
              <div v-if="OwnerPayment.UnitModelId && !OwnerPayment.BuildingNumberId" class="warning-message">سوف يتم تحميل المصروفات على جميع الوحدات في هذا النموذج!</div>
              </div>


              <div style="width: 200px;  padding:10px ;">
              <label style="font-size: 16px;font-family:almarai ;">{{ $t("buildingnumber") }}</label>
              <v-select
              label="buildingName"
                  class="mt-2 w-full"
                  name="buildingName"
                  v-model="OwnerPayment.BuildingNumberId"
                  :reduce="(x) => x.id"
                  :options="buildingNumberOptions"
                  :disabled="!OwnerPayment.UnitModelId || selectAllUnits"
                  @input="getLevelsByBuldingId(OwnerPayment.BuildingNumberId)"
              />
            </div>

            <div style="width: 200px;  padding:10px ;">
          <label style="font-size: 16px;font-family:almarai ;">{{ $t("Round") }}</label>
          <v-select
                  label="levelName"
                  class="mt-2 w-full"
                  name="levelName"
                  v-model="OwnerPayment.LevelId"
                  :reduce="(x) => x.id"
                  :options="LevelsOptions"
                  :disabled="!OwnerPayment.BuildingNumberId || selectAllUnits"
                  @input="getUnitNumberByLevelId(OwnerPayment.LevelId)"
              />
        </div>


        <div style="width: 200px;  padding:10px ;">
          <label style="font-size: 16px;font-family:almarai ;">{{ $t("UnitNumber") }}</label>
          <v-select
                  label="unitNumber"
                  class="mt-2 w-full"
                  name="unitNumber"
                  v-model="OwnerPayment.UnitId"
                  :reduce="(x) => x.id"
                  :options="UnitNumberoptions"
                  :disabled="!OwnerPayment.LevelId || selectAllUnits"
              />
        </div>






      </div>
      <br>
      <h4 style="font-family:almarai ;">أضف المصروف</h4>
      <br>
      <div class="vx-row w-full">
        <div style="width: 200px; margin:6px; padding:10px ;">
          <label style="font-size: 16px;font-family:almarai ;">{{ $t("Amount") }}</label>
          <vs-input
            v-model="OwnerPayment.Value"
            class="w-full"
            :data-vv-as="$t('Round')"
            name="round"
            v-validate="'required'"
          />
        </div>
        <div style="width: 200px;  padding:10px ;">
          <label style="font-size: 16px;font-family:almarai ;">{{ $t("Typeofreceivable") }}</label>
          <v-select
            label="name"
            class="mt-2 w-full"
            name="name"
            v-model="OwnerPayment.PaymentTypeId"
            :reduce="(x) => x.id"
            :options="PaymentTypsOptions"

          />
        </div>
        <div class="vx-col lg:w-1/2">
          <label style="font-size: 16px;font-family:almarai ;">{{ $t("Description") }}</label>
          <vs-textarea
            v-model="OwnerPayment.Description"
            class="w-full"
            :data-vv-as="$t('Round')"
            name="round"
            v-validate="'required'"
          />
        </div>
      </div>

    </div>
  </div>
  <div class="flex justify-end mt-4">
    <vs-button  color="#10cd10" style="font-size: 16px;font-family:almarai; margin-left: 15px;"  @click.stop="openConfirm(OwnerPayment)">
            {{ $t("AddExpence") }}
          </vs-button>
    <vs-button color="#eb5252" style="font-size: 16px;font-family:almarai;" @click="cancel">
            {{ $t("Cancel") }}
          </vs-button>
  </div>
</vx-card>
  </div>
</template>

<script>
import VxCard from "../../components/vx-card/VxCard.vue";
import { domain } from "@/gloabelConstant.js";
import moduleUintModel from "@/store/UintModel/moduleUintModel.js";
import modulePaymentType from "@/store/PaymentType/modulePaymentType.js";
import moduleOwnerPaymentLogs from "@/store/OwnerPaymentLogs/moduleOwnerPaymentLogs.js";

import axios from "@/axios.js";

export default {
  components: { VxCard },
  data() {
    return {
      baseURL: domain,
      user_not_found: false,
      activeTab: 0,

      OwnerPayment: {

        UnitModelId: null,
        BuildingNumberId: null,
        UnitId: null,
        LevelId: null,
        RecivedType: "",
        Description: "",
        PaymentTypeId:"",
        Value: 0,
        IsAllUnits: false,
      },

      buildingNumberOptions: [],
      LevelsOptions: [],
      UnitNumberoptions: [],
      selectAllUnits: false
    };
  },
  computed: {
    PaymentTypsOptions() {
        debugger;
        return this.$store.state.PaymentTypeList.PaymentTypes;
      },
      unitModelOptions() {
        debugger;
        return this.$store.state.moduleUintModelList.unitModelOptions;
      }
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    openConfirm(data) {
  debugger;
  this.rowDataForDelete = data;

  let text = "";

  if (this.OwnerPayment.IsAllUnits) {
    text = "سوف يتم تحميل المصروف على جميع الوحدات.";
  } else {
    if (this.OwnerPayment.UnitModelId) {
      text = "سوف يتم تحميل المصروف على حميع الوحدات في هذا النموذج";
    } else if (this.OwnerPayment.UnitId) {
      text = "سوف يتم تحميل المصروف على هذه الوحدة فقط";
    }
  }

  this.$vs.dialog({
    type: "confirm",
    color: "success",
    title: `تحميل المصروف`,
    text: text,
    accept: this.AddExpense,
    acceptText: this.$t("Confirm"),
    cancelText: this.$t("Cancel"),
  });
},


     getBuildingNumbersByModelId(modelId) {
      debugger;
      if (!modelId) return;

      try {
        const response = axios.get("API/UnitData/GetBuildingByModelId?Id="+modelId)
        .then((res) => {
        debugger;
        this.buildingNumberOptions = res.data.data;
        console.log(this.buildingNumberOptions);
      });
        const data =  response.json();

        if (data.message === "Data list: ") {
          const buildingNumbers = data.data.map(item => item.buildingNumber);
          this.buildingNumberOptions = buildingNumbers;
        } else {
          this.buildingNumberOptions = [];
          console.log("No data found");
        }
      } catch (error) {
        console.error("Error fetching building numbers:", error);
      }
    },
    getLevelsByBuldingId(buldingId) {
      debugger;
      if (!buldingId) return;

      try {

        const response = axios.get("API/UnitData/GetlevelsByBuildingId?Id="+buldingId)
        .then((res) => {
        debugger;
        this.LevelsOptions = res.data.data;
        console.log(this.LevelsOptions);
      });
        const data =  response.json();

        if (data.message === "Data list: ") {
          const LevelsOptions = data.data.map(item => item.LevelsOptions);
          this.LevelsOptions = LevelsOptions;
        } else {
          this.LevelsOptions = [];
          console.log("No data found");
        }
      } catch (error) {
        console.error("Error fetching building numbers:", error);
      }
    },
    getUnitNumberByLevelId(levelId) {
      debugger;
      if (!levelId) return;

      try {

        const response = axios.get("API/UnitData/GetUnitDataBylevelsId?Id="+levelId)
        .then((res) => {
        debugger;
        this.UnitNumberoptions = res.data.data;
        console.log(this.UnitNumberoptions);
      });
        const data =  response.json();

        if (data.message === "Data list: ") {
          const UnitNumberoptions = data.data.map(item => item.UnitNumberoptions);
          this.UnitNumberoptions = UnitNumberoptions;
        } else {
          this.UnitNumberoptions = [];
          console.log("No data found");
        }
      } catch (error) {
        console.error("Error fetching building numbers:", error);
      }
    },
    showAddSuccess() {
      this.$vs.notify({
        color: "success",
        position: "bottom-center",
        title: this.$t("Success"),
        text: this.$t("تم تحميل المصروف")
      });
    },
    showAddFailed(err) {
      if (err != "") {
        this.$vs.notify({
          color: "failed",
          position: "bottom-center",
          title: this.$t("حدث خطأ اثناء تحميل المصروف"),
          text: this.$t("تم تحميل المصروف")
        });
      } },

    AddExpense() {

        debugger;
        this.$store
          .dispatch("OwnerPaymentLogsList/addItem", this.OwnerPayment)
          .then(() => {
            debugger;
            window.showAddSuccess();
            this.initValues();
            this.$vs.loading.close();
            this.cancle();
          })
          .catch((err) => {
            if (err.response.status == 400) {
              window.showAddFailed(err.response.data.message);
            } else {
              window.showAddFailed("");
            }
            this.$vs.loading.close();
          });

    },
    reset_data() {
      this.initValues();
    },

    initValues() {
      this.OwnerPayment = {};
    },

    toggleAllUnits() {
      // Toggle the disabled state of other inputs based on selectAllUnits value
      this.OwnerPayment.UnitModelId = this.selectAllUnits ? null : this.OwnerPayment.UnitModelId;
      this.OwnerPayment.BuildingNumberId = this.selectAllUnits ? null : this.OwnerPayment.BuildingNumberId;
      this.OwnerPayment.LevelId = this.selectAllUnits ? null : this.OwnerPayment.LevelId;
      this.OwnerPayment.UnitId = this.selectAllUnits ? null : this.OwnerPayment.UnitId;
    }


  },
  created() {
//payment type
if (!modulePaymentType.isRegistered) {
  debugger;
  this.$store.registerModule("PaymentTypeList", modulePaymentType);
  modulePaymentType.isRegistered = true;
}
debugger;
this.$store.dispatch("PaymentTypeList/fetchDataListItems");

//unit model
if (!moduleUintModel.isRegistered) {
  this.$store.registerModule("moduleUintModelList", moduleUintModel);
  moduleUintModel.isRegistered = true;
}
debugger;
this.$store.dispatch("moduleUintModelList/fetchDataListItems");


    if (!moduleOwnerPaymentLogs.isRegistered) {
      this.$store.registerModule("OwnerPaymentLogsList", moduleOwnerPaymentLogs);
      moduleOwnerPaymentLogs.isRegistered = true;
    }
    this.$store
        .dispatch("OwnerPaymentLogsList/fetchDataListItems")


  },


    mounted() {
      this.isMounted = true;
    },

};
</script>

<style>
.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-wrapper input[type="checkbox"] {
  width: 20px; /* Set width of checkbox */
  height: 20px; /* Set height of checkbox */
  margin-right: 10px;
  margin-bottom: 10px;/* Adjust spacing between checkbox and label */
}

.checkbox-label {
  margin-right: 5px; /* Adjust spacing between checkbox and label */
  margin-bottom: 10px;
}

.warning-message {
  background-color: #fff3cd; /* Set background color */
  color: #856404; /* Set text color */
  padding: 10px; /* Add padding */
  border: 1px solid #ffeeba; /* Add border */
  border-radius: 4px; /* Add border radius */
  font-size: 14px; /* Set font size */
  margin-top: 5px; /* Add top margin */
}

</style>
