export default {
  ADD_ITEM(state, item) {
    state.UintModels.unshift(item);
  },
  SET_UintModels(state, UintModels) {
    state.UintModels = UintModels;
    state.unitModelOptions = UintModels;
  },
  UPDATE_UintOwnerType(state, UintOwnerType) {
    const UintOwnerTypeIndex = state.UintModels.findIndex(p => p.ID == UintOwnerType.ID);
    if (UintOwnerTypeIndex != -1) {
      Object.assign(state.UintModels[UintOwnerTypeIndex], UintOwnerType);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.UintModels.findIndex(p => p.ID == itemId);
    state.UintModels.splice(ItemIndex, 1);
  }
};

