export default {
  ADD_ITEM(state, item) {
    state.PaymentTypes.unshift(item);
  },
  SET_PaymentType(state,  PaymentTypes) {
    state.PaymentTypes =  PaymentTypes;
  },
  UPDATE_PaymentType(state,  PaymentType) {
    const  PaymentTypesIndex = state.PaymentTypes.findIndex(p => p.ID ==  PaymentType.ID);
    if (PaymentTypesIndex != -1) {
      Object.assign(state. PaymentTypes[PaymentTypesIndex],  PaymentType);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.PaymentTypes.findIndex(p => p.ID == itemId);
    state.PaymentTypes.splice(ItemIndex, 1);
  }
};

