
import state from "./modulePaymentTypeState.js"
import mutations from "./modulePaymentTypeMutations.js"
import getters from "./modulePaymentTypeGetters.js"
import actions from './modulePaymentTypeActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
