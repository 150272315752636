
import state from "./moduleOwnerPaymentLogsState.js"
import mutations from "./moduleOwnerPaymentLogsMutations.js"
import getters from "./moduleOwnerPaymentLogsGetters.js"
import actions from './moduleOwnerPaymentLogsActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
